import React from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'

import {useFetch} from '../lib/use-fetch'
import Spinner from '../lib/spinner'
import SocialLogin from '../components/SocialLogin'

const authorize = '/authorize'
const setCliektKeyEndpoint = '/client-key'

function Login ({location}) {
  const {loading} = useFetch('GET', setCliektKeyEndpoint)
  if (loading) {
    return <Spinner />
  }

  const {search} = location
  if (search) {
    const queryString = qs.parse(search, {ignoreQueryPrefix: true})
    if (queryString.appId && !queryString.providerId) {
      return (
        <SocialLogin
          appId={queryString.appId}
          authorizeUrl={authorize}
          redirectUrl={queryString.redirect}
        />
      )
    }
  }

  window.location.replace(`${window.location.origin}${authorize}${search || ''}`)
  return null
}
Login.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
}
export default Login
