import React from 'react'
import PropTypes from 'prop-types'

import {useFetch} from '../lib/use-fetch'
import Spinner from '../lib/spinner'

const oauthTokenEndpoint = '/oauth/token'

const headersContentType = {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}

const emptyHeaders = {}

export default function OauthTokenConsumer ({tokenQuery, tokenBody, onError}) {
  const endpoint = oauthTokenEndpoint + (tokenQuery || '')

  const headers = tokenBody ? headersContentType : emptyHeaders

  const {status, error, redirectLocation, loading} = useFetch('POST', endpoint, tokenBody, headers)
  if (loading) {
    return <Spinner />
  }
  if (error || status !== 200) {
    onError(status, error)
    return <span />
  }

  const replaceLocation = `${window.location.origin}${redirectLocation}`
  if (redirectLocation && redirectLocation !== replaceLocation) {
    window.location.replace(replaceLocation)
  }
  return null
}
OauthTokenConsumer.propTypes = {
  onError: PropTypes.func.isRequired,
  tokenBody: PropTypes.string,
  tokenQuery: PropTypes.string
}
