/*
 * Copyright © 2019-present Mia s.r.l.
 * All rights reserved
 */

import en from '../strings/locales/en.json'
import it from '../strings/locales/it.json'

export default {
  en,
  it
}
