import React from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'

import OauthTokenConsumer from '../components/OauthConsumer'
import OauthError from '../components/OauthError'

function OauthCallback ({location, onError, history}) {
  const query = qs.parse(location.search, {ignoreQueryPrefix: true})

  const onErrorButtonClick = () => {
    history.push('/')
  }

  if (query.error || query.error_description) {
    return (
      <OauthError
        error={query.error}
        errorDescription={query.error_description}
        onClick={onErrorButtonClick}
      />
    )
  }

  const tokenBody = qs.stringify({
    code: query.code,
    state: query.state
  })
  return <OauthTokenConsumer onError={onError} tokenBody={tokenBody} />
}
OauthCallback.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  onError: PropTypes.func.isRequired
}

export default OauthCallback
