import axios from 'axios'
import {useEffect, useState} from 'react'

const cmsEntryPoint = '/app_dataentry/'

export function useFetch (method, url, body, headers) {
  const [status, setStatus] = useState(0)
  const [redirectLocation, setRedirectLocation] = useState(cmsEntryPoint)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [responseBody, setResponseBody] = useState(null)
  useEffect(
    () => {
      setLoading(true)
      axios({
        url,
        method: method.toLowerCase(),
        headers,
        data: body
      })
        .then(response => {
          setStatus(response.status)
          const locationHeader = response.headers.location
          if (locationHeader) {
            setRedirectLocation(locationHeader)
          }
          setLoading(false)
          setResponseBody(response.data)
          return response
        })
        .catch(error => {
          if (error.response) {
            setStatus(error.response.status)
          }
          setError(error)
          setLoading(false)
        })
    },
    [body, headers, method, url]
  )
  return {status, redirectLocation, error, loading, responseBody}
}
