import React from 'react'
import PropTypes from 'prop-types'
import {Button, Alert} from 'antd'
import {FormattedMessage} from 'react-intl'

function OauthError ({error, errorDescription, onClick}) {
  return (
    <div style={styles.container}>
      <Alert
        description={errorDescription}
        message={error}
        style={styles.alert}
        type='warning'
      />
      {onClick ? (
        <Button
          onClick={onClick}
          style={styles.button}
          type='primary'
        >
          <FormattedMessage id='retry-login' />
        </Button>
      ) : null}
    </div>
  )
}

OauthError.propTypes = {
  error: PropTypes.string.isRequired,
  errorDescription: PropTypes.string.isRequired,
  onClick: PropTypes.func
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  },
  alert: {
    minWidth: '40%',
    maxWidth: '80%',
    marginBottom: 30
  },
  button: {
    width: 200
  }
}

export default OauthError
